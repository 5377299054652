@tailwind base;
@tailwind components;
@tailwind utilities;


/* // FONTS */
@import "assets/scss/custom/fonts";

/* // general */
@import "assets/scss/custom/general";

/* // components */
@import "assets/scss/components/buttons";
@import "assets/scss/components/breadcrumb";

// /* // structure */
@import "assets/scss/custom/structure/topnav";
@import "assets/scss/custom/structure/footer";

// // pages
// @import "assets/scss/custom/pages/helper";
// @import "assets/scss/custom/pages/hero";
// @import "assets/scss/custom/pages/countdown";
// @import "assets/scss/custom/pages/portfolio";
@import "assets/scss/custom/pages/contact";

// // Plugins
// @import "assets/scss/custom/plugins/swiper-slider";
// @import "assets/scss/custom/plugins/testi";
// @import "assets/scss/custom/plugins/choices";
// @import "assets/scss/custom/plugins/datepicker";
